<template>
  <a href="#" class="close form-wrap__close">
    <svg fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 1.5L1.5 8.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
      <path d="M1.5 1.5L8.5 8.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
    </svg>
  </a>
</template>

<script>
export default {
}
</script>